import React from "react";
import Loader from "../images/loader1.jpg";


function ShortTermTable({ data,shortVar }) {
  return (
    <div className="">
      <div className="overflow-x-auto" >


        <table className="min-w-full divide-y divide-gray-200 mt-12">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID EQUIPEMENT 
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID TICKET
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                DATE DE CREATION
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                NOMBRE D'OCCURENCE
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                DUREE DEPUIS LA DERNIERE PANNE (JOURS)
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                DESCRIPTION
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200 ">
            {data.map((item, index) => (
              <tr key={index}>
                {item.map((cell, cellIndex) => (
                  <td key={cellIndex} className="px-6 py-4 whitespace-normal ">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.length ? null : (
        <div>
                  {shortVar ? (<div>
      <h1 className="font-bold text-3xl text-red-600 text-center mb-10">There is no data available </h1>
      </div>):(    <div>
          <div className="flex items-center justify-center mt-10">
            <img className="h-16 w-16" src={Loader} alt="loading..." />
          </div>
        </div>) }

        </div>
      )}

    </div>
  );
}

export default ShortTermTable;
