import React, { useState, useEffect } from "react";
import axios from "axios";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import "./App.css";
import domtoimage from 'dom-to-image';
import LongTermTable from "./Components/LongTermTable";
import ShortTermTable from "./Components/ShortTermTable";
import logo from "./images/Logo_Toulouse.png"
const App = () => {
  const [apiDataLong, setApiDataLong] = useState([]);
  const [longTerm, setLongTerm] = useState(6);
  const [longCount, setLongCount] = useState(4);
  const [longVar, setLongVar] = useState(false);
  const [shortVar, setShortVar] = useState(false);
  const [longTermList, setLongTermList] = useState(["ACEEEEEE"])
  const [apiDataShort, setApiDataShort] = useState([]);
  const [shortTerm, setShortTerm] = useState(1);
  const [shortCount, setShortCount] = useState(2);

  async function getDateLong() {
    const response = await axios.get(
      `https://sippro-gmao.fr:8443/displayAnomalies?months=${longTerm}&occurance=${longCount}`
    );
    setApiDataLong(response.data);
    console.log(`App : ${response.data.length}`);
  }

  async function getDateShort() {
    setLongTermList(["ACEEEEEE"])
    const response = await axios.get(
      `https://sippro-gmao.fr:8443/displayAnomalies?months=${shortTerm}&occurance=${shortCount}`
    );
    const firstElementsList = response.data.map(subArray => subArray[0]);
    setLongTermList(firstElementsList)
    setApiDataShort(response.data);
    console.log(`App : ${response.data.length}`);
  }

  const longApi = async () => {
    setApiDataLong(["ACEEEEEE"]);
    setLongVar(false);
    const response = await axios.get(
      `https://sippro-gmao.fr:8443/displayAnomalies?months=${longTerm}&occurance=${longCount}`
    );
    if (response.data.length === 0) {
      setLongVar(true);
    }
    setApiDataLong(response.data);
  };

  const shortApi = async () => {
    setApiDataShort([]);
    setLongTermList(["ACEEEEEE"])
    setShortVar(false)
    const response = await axios.get(
      `https://sippro-gmao.fr:8443/displayAnomalies?months=${shortTerm}&occurance=${shortCount}`
    );
    if (response.data.length===0){
      setShortVar(true)
    }
        const firstElementsList = response.data.map(subArray => subArray[0]);
    setLongTermList(firstElementsList)
    setApiDataShort(response.data);
  };

  useEffect(async () => {
    // getDataAll();
    await getDateShort();
    await getDateLong();
    
  }, []);

  const generatePDF = () => {
    const element = document.getElementById(`vertical-bar-charts`); // Replace 'pdf-content' with the ID of the element containing the content you want to convert to PDF
    const opt = {
      margin: [5, 0, 1, 0],
      filename: `Anomalies.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }, // Set orientation to 'landscape'
    };

    html2pdf().from(element).set(opt).save();
  };

const captureScreenshot = async (sectionId) => {
  const section = document.getElementById(sectionId);
  
  if (section) {
    section.style.backgroundColor = 'white';
    await new Promise((resolve) => requestAnimationFrame(resolve));
    domtoimage.toPng(section).then((dataUrl) => {
      // Create a hidden anchor element to trigger the download
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `${sectionId}.png`; // Specify the filename

      // Trigger a click event on the anchor to start the download
      link.click();
    });
  }
};

  return (
    <div className="max-w-screen-xl mx-auto">
      <div>
        <div className="mx-6 flex items-center justify-end space-x-2">
          <button
            className=" mt-2 bg-blue-500 text-white px-4 py-2 rounded"
            onClick={generatePDF}
          >
            Générer PDF
          </button>
          <button
            className=" mt-2 bg-blue-500 text-white px-4 py-2 rounded"
            onClick={() => {
              captureScreenshot("Long-Term-Dashboard"),
                captureScreenshot("short-Term-Dashboard");
            }}
          >
            Générer Image
          </button>
          <button className="  mt-2 bg-blue-500 text-white px-4 py-2 rounded">
            <a
              href="http://sippro-gmao.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
             GMAO
            </a>
          </button>
        </div>
      </div>
      
      <div className="container mx-auto " id="vertical-bar-charts">

        <div className="" id="Long-Term-Dashboard">
              <div className="flex justify-center items-center">
      <img src={logo} style={{height:"75px", width:"95px"}} alt="logo" /></div>
      
              <div className="font-bold text-3xl text-blue-600 mb-5">
          {" "}
          Pannes sur longue période 
        </div>
        <div>
        Nombre de mois:
          <input
            className="border rounded-lg p-2 ml-2 mr-2 w-16 h-8 mb-6"
            name="Enter Months"
            type="text"
            value={longTerm}
            onChange={(e) => setLongTerm(e.target.value)}
          />
          Nombre d'occurence:
          <input
            className="border rounded-lg p-2 ml-2 mr-2 w-16 h-8"
            name="Enter Occurance"
            type="text"
            value={longCount}
            onChange={(e) => setLongCount(e.target.value)}
          />
          <button
            className="w-auto h-8 px-6 ml-4 rounded-lg items-center justify-center bg-blue-500 text-white"
            onClick={() => longApi()}
          >
            rafraichir
          </button>
          </div>
          <LongTermTable data={apiDataLong} longVar={longVar} longTermList={longTermList}/>
        </div>
              
        <div id="short-Term-Dashboard">
        <div className="flex justify-center items-center">
      <img src={logo} style={{height:"75px", width:"95px"}} alt="logo" /></div>
                <div className="font-bold text-3xl text-blue-600">
          {" "}
          Pannes sur courte période {" "}
        </div>
        Nombre de mois:
          <input
            className="border rounded-lg p-2 ml-2 mr-2 w-16 h-8 mb-6 mt-6"
            name="Enter Months"
            type="text"
            value={shortTerm}
            onChange={(e) => setShortTerm(e.target.value)}
          />
          Nombre d'occurrence:
          <input
            className="border rounded-lg p-2 ml-2 mr-2 w-16 h-8 mb-6"
            name="Enter Occurance"
            type="text"
            value={shortCount}
            onChange={(e) => setShortCount(e.target.value)}
          />
          <button
            className="w-auto h-8 px-6 ml-4 rounded-lg items-center justify-center bg-blue-500 text-white"
            onClick={() => {shortApi()}}
          >
            rafraichir
          </button>
          <ShortTermTable data={apiDataShort} shortVar={shortVar} />
        </div>
      </div>
    </div>
  );
};
export default App;
